<template>
  <div class="w-full">
    <template v-for="item in links" :key="item">
      <NavDivider v-if="(item as Divider).rel === 'divider'" />
      <NavGroupTitle
        v-else-if="(item as GroupTitle).rel === 'groupTitle' && !collapsed"
        :title="(item as GroupTitle).title"
      />
      <NavAccordion
        v-else-if="(item as GroupTitle).rel === 'group' && !collapsed"
        :icon="item.icon"
        :label="(item as GroupTitle).label"
        :items="item.items"
        :collapsed="collapsed"
      />
      <div v-else>
        <NavItem :to="(item as Navigatable).to" :data-testid="(item as Navigatable).label.toLowerCase() + '-sidenav'">
          <component :is="(item as Navigatable).icon" class="h-5 w-5" />
          <span v-if="!collapsed">{{ (item as Navigatable).label }}</span>
        </NavItem>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import * as Icons from '@pulse/components/icons';
import { storeToRefs } from 'pinia';

import { useMerchantStore } from '~/store/merchant';
import { useUserStore } from '~/store/user';

const user = useUserStore();
const store = useMerchantStore();
const { currentStore } = storeToRefs(store);
const { isGlobalSupport } = storeToRefs(user);

type Navigatable = {
  icon: unknown;
  label: string;
  to: string;
};

type Divider = {
  rel: 'divider';
};

type GroupTitle = {
  rel: 'groupTitle';
  title: string;
};

type NavItem = Navigatable | Divider | GroupTitle | undefined;

const links = computed<NavItem[]>(
  () =>
    [
      can(can.analytics.read) && {
        icon: Icons.LineDashboard,
        label: 'Dashboard',
        to: '/dashboard',
      },
      can(can.claim.read) && {
        icon: Icons.LineClipboardAlt,
        label: 'Claims',
        to: '/claims',
      },
      can(can.order.read) && {
        icon: Icons.QueueList,
        label: 'Orders',
        to: '/orders',
      },
      can(can.finance.read) && {
        icon: Icons.LineComparison,
        items: [
          can(can.payout.read) && {
            icon: Icons.LineMoneyWithdraw,
            label: 'Payouts',
            to: `/${currentStore?.value?.slug}/finance/payouts`,
          },
          can(can.invoice.read) && {
            icon: Icons.LineUsdCircle,
            label: 'Payments',
            to: `/${currentStore?.value?.slug}/finance/payments`,
          },
          can(can.financeAdmin.read) && {
            icon: Icons.LineFileLandscape,
            label: 'Admin',
            to: `/${currentStore?.value?.slug}/finance/admin`,
          },
        ].filter(Boolean) as NavItem[],
        label: 'Finance',
        rel: 'group',
      },
      !store.multipleStoresSelected &&
        !isGlobalSupport.value && {
          rel: 'divider',
        },
      can(can.storeCredit.read) &&
        storeCreditEnabled.value && {
          icon: Icons.LineTransaction,
          label: 'Store Credit',
          to: '/store-credit',
        },
      storeCreditEnabled.value && {
        rel: 'divider',
      },

      currentStore?.value &&
        !store.multipleStoresSelected &&
        can(can.teamMember.read) && {
          icon: Icons.LineUsersAlt,
          label: 'Team Members',
          to: `/${currentStore?.value?.slug}/team-members`,
        },
      currentStore?.value &&
        !store.multipleStoresSelected &&
        can(can.settingsGeneral.read) && {
          icon: Icons.LineGlobe,
          label: 'Settings',
          to: `/${currentStore?.value?.slug}/settings`,
        },
      currentStore?.value &&
        !store.multipleStoresSelected &&
        store.can.splitTest &&
        can(can.abTests.read) && {
          icon: Icons.ArrowsRightLeft,
          label: 'A/B Tests',
          to: `/${currentStore?.value?.slug}/tests`,
        },
      {
        rel: 'divider',
      },
      (can(can.adminStores.read) ||
        can(can.adminUsers.read) ||
        can(can.whitelabel.list) ||
        can(can.globalMacro.read)) && {
        icon: Icons.LineFolder,
        items: [
          can(can.adminStores.read) && {
            icon: Icons.LineRocket,
            label: 'Stores',
            to: '/admin/stores',
          },
          can(can.adminUsers.read) && {
            icon: Icons.LineUsersAlt,
            label: 'Users',
            to: '/admin/users/active',
          },
          can(can.whitelabel.list) && {
            icon: Icons.LinePalette,
            label: 'Whitelabel',
            to: '/admin/whitelabel',
          },
          can(can.globalMacro.read) && {
            icon: Icons.LineBolt,
            label: 'Global Macros',
            to: '/admin/global-macros',
          },
        ].filter(Boolean) as NavItem[],
        label: 'Admin',
        rel: 'group',
      },
    ].filter(Boolean) as NavItem[],
);

const collapsed = inject('collapsed', true);
const storeCreditEnabled = computed(() => {
  return currentStore?.value?.generalSettings?.storeCreditType;
});
</script>

<style lang="scss">
.fill-fosho {
  path {
    @apply fill-typography-disabled;
  }
}
</style>
